import React, { Component } from "react";

import { GatsbyImage } from "gatsby-plugin-image"

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card } from 'components/Theme/Grid';
import { h1 } from 'components/Theme/Copy';
import Button from 'components/Theme/Button';

const SimilarSection = styled(Container)`
  background-color:${p => p.theme.brandSecondary};
  margin-top:1.5rem;
  padding:6rem;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  ${media.lessThan("tablet")`
    padding:6rem 2rem;
    margin-top:0;
  `}
`;

const SimilarContainer = styled(Card)`
  width:80rem;
  display:flex;
  justify-content:center;
  padding:2rem;
  align-items:center;
  ${media.lessThan("tablet")`
    flex-direction:column;
    text-align:center;
    width:30rem;
  `}
`;

const Heading = styled(h1)`
  margin-bottom:3rem;
  font-size:1.4rem;
  text-transform:uppercase;
  color:#fff;
  text-align:center;
  letter-spacing:0.1em;

  ${media.lessThan("tablet")`
    font-size:1.8rem;
  `}
`;

const Asset = styled(GatsbyImage)`
  margin-right:4rem;
  box-shadow: 0 0.4rem 1.5rem 0.2rem rgba(0, 0, 0, .2);
  transition: left 0.5s;
  transition-timing-function: linear;
  width:100%;
  flex-basis:50%;
  ${media.lessThan("tablet")`
    margin-right:0;
    margin-bottom:2rem;
  `}
`;

const RecipeInfo = styled.div `
  flex-basis:50%;
`;

const SimilarHeading = styled(h1)`
  font-size:5rem;
  line-height:4.8rem;
  color:#fff;
  font-weight:200;
  margin-bottom:2rem;
  ${media.lessThan("tablet")`
    font-size:3rem;
    line-height:3.2rem;
  `}
`;

const SimilarDescription = styled.div `
  color:#fff;
  line-height:2.4rem;
  font-size:1.4rem;
`;

const RecipeButton = styled(Button)`
  margin-top:2rem;
`;

const RenderPage = (props) => (
  props.data.posts.map(recipe => {
    if ( props.data.post.handle !== recipe.node.handle ) {
      return (
        <SimilarSection neumorphic>
          <Heading>You might also like</Heading>
          <SimilarContainer>
            <Asset image={recipe.node.heroAsset.gatsbyImageData}></Asset>
            <RecipeInfo>
              <SimilarHeading>{ recipe.node.heroTitle }</SimilarHeading>
              <SimilarDescription>{ recipe.node.heroDescription }</SimilarDescription>
              <RecipeButton to={ '/recipes/' + recipe.node.handle }>SEE FULL RECIPE</RecipeButton>
            </RecipeInfo>
          </SimilarContainer>
        </SimilarSection>
      )
    }
    return null
  })
)

class Similar extends Component {

    render() {
      const data = this.props.data;

      return <RenderPage data={data}/>
    }
}

export default Similar