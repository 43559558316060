import React, { Component } from "react";

import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h3, body } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

const HeroContainer = styled(Container)`
  background-color:${p => p.theme.brandPrimaryAlt};
  flex-wrap:nowrap;
  margin-bottom:0;

  ${media.lessThan("tablet")`
    flex-direction:column-reverse;
  `}
`;

const HeroCopyCard = styled(Card)`
  flex-basis:30%;
  height:60rem;
  margin-bottom:0;

  ${media.lessThan("tablet")`
    padding:4rem 2rem;
    height:auto;
  `}
`;

const HeroAssetCard = styled(Card)`
  height:60rem;
  flex-basis:70%;
  margin-bottom:0;
`;

const HeroAsset = styled(GatsbyImage)`
  width:100% !important;
  height:100%;
`;

const HeroCopy = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: center;
  padding:0 4rem;
  ${media.lessThan("tablet")`
    font-size:2rem;
    height:auto;
    padding:0 1.5rem;
  `}
`;

const HeroHeadline = styled(h1)`
  font-size:10rem;
  color:#fff;
  line-height:10rem;
  margin-bottom:2rem;
  text-align:left;
  ${media.lessThan("tablet")`
    font-size:4rem;
    padding:0;
    text-align:left;
  `}
`;

const HeroDescription = styled(h3)`
  color:#fff;
  line-height:2.4rem;
  font-size:1.4rem;
  ${media.lessThan("tablet")`
    margin-bottom:3rem;
  `}
`;


const Author = styled(body)`
  width:100%;
  padding-top:2rem;
  color:${p => p.theme.white};
  display:flex;
  align-items: flex-start;  
  font-size:1.6rem;
`;

const AuthorAvatar = styled(GatsbyImage)`
  height:24px;
  width:24px;
  margin:0.5rem 0.5rem 0 0.5rem;
`;

class Hero extends Component {
  render() {
    const { heroDescription, heroTitle, heroAsset, author, authorLink, authorAvatar } = this.props.data;

    return ( 
      <HeroContainer neumorphic>

        <HeroCopyCard sm={1}>
          <HeroCopy>
            <HeroHeadline> { heroTitle } </HeroHeadline>
            <HeroDescription> { heroDescription} </HeroDescription>

            { author && 
            <Link to={authorLink}>
              <Author>
                by <AuthorAvatar image={authorAvatar.gatsbyImageData} /> { author }
              </Author>
            </Link>
          }
          </HeroCopy>
        </HeroCopyCard>

        <HeroAssetCard sm={1}>
          <HeroAsset image = { heroAsset.gatsbyImageData }/>
        </HeroAssetCard>

        
      </HeroContainer>
    )
  }
}



export default Hero