import React, { Component } from "react";
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { Container, Card } from 'components/Theme/Grid';
import { h3, body } from 'components/Theme/Copy';

const IngredientsContainer = styled(Container)`
  width:100%;
`;

const IngredientsCard = styled(Card)`
  padding:6rem;
  font-size:1.6rem;
  flex-direction: column;
  
  & strong {
    color:${p => p.theme.brandPrimary};
    font-weight:normal;
  }
  ${media.lessThan("tablet")`
    padding:3rem;
  `}
`;

const Heading = styled(h3)`
  margin:0 0 3rem 0;
  font-size:1.4rem;
  letter-spacing:0.1em;

  ${media.lessThan("tablet")`
    font-size:1.6rem;
    margin-left:0;
    padding:0;
  `}
`;

const Ingredient = styled.div`
  margin-bottom:2rem;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MeasurementsContainer = styled.div`
  display:flex;
  flex-direction: row;
  
  justify-content:stretch;
`;

const Qty = styled(body)`
  color:${p => p.theme.brandPrimary};
  font-weight:bold;
  font-size:1.6rem;
  margin-bottom:0;
  line-height:3rem;
  text-align:right;
`;

const Measurement = styled(body)`
  color:${p => p.theme.brandPrimary};
  font-weight:bold;
  font-size:1.6rem;
  margin-left:0.5rem;
  margin-bottom:0;
  line-height:3rem;
`;

const ServingCalc = styled(body)`
  margin-bottom:4rem;
  font-size:1.6rem;
`;

const SliderWrapper = styled.div`
  margin-top:1rem;
  width:50%;
`;

const Label = styled(body)`
  font-size:1.6rem;
  line-height:3rem;
  text-wrap:wrap;
  width:calc(100% - 11rem);
  margin-bottom:0;
`;

class Ingredients extends Component {

  constructor(props) {
    super(props)
    this.state = {  ingredients: props.data.ingredientsList, 
                    servingSize: parseInt(props.servingSize),
                    originalServingSize: parseInt(props.servingSize) }
  }

  onServingsChange = (val) => {
    this.setState({servingSize: parseInt(val)})
  }

  qty = (initialVal) => {
    let val = (parseFloat(initialVal) / this.state.originalServingSize) * this.state.servingSize;
    if (val % 1 != 0) {
      return val.toFixed(1)
    } else {
      return val 
    }
  }

  render() {
    const { ingredients, ingredientsList } = this.props.data;
    
    return ( 
        <IngredientsContainer>
          <IngredientsCard>
            <Heading>INGREDIENTS</Heading>

            <ServingCalc>
              <div>Servings: {this.state.servingSize}</div>

              { typeof window !== `undefined` && 
                <SliderWrapper>  
                  <Slider defaultValue={this.state.servingSize} 
                          min={1}
                          max={12}
                          onChange={this.onServingsChange}
                          trackStyle={{backgroundColor: '#5c5d5f'}}
                          handleStyle={{borderColor: '#000'}} />
                </SliderWrapper>
              }
            </ServingCalc> 

            {(ingredientsList||[]).map((ingredient) => { 
              let qty = this.qty(ingredient.qty)
              return (
                <Ingredient>
                  <Label>{ingredient.label}</Label>
                  <MeasurementsContainer>
                    <Qty>{qty === 0 ? '' : qty}</Qty> <Measurement>{ingredient.measurement}</Measurement>
                  </MeasurementsContainer>
                  
                </Ingredient>
              )
            })}
          </IngredientsCard>
        </IngredientsContainer>
    )
  }
}

export default Ingredients