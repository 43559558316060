import React, { Component } from "react";
import { graphql } from 'gatsby'
import Layout from "components/layout";
import SEO from "components/seo";
import _ from 'lodash';
import Hero from 'components/Page/Recipe/Hero';
import Ingredients from 'components/Page/Recipe/Ingredients';
import Directions from 'components/Page/Recipe/Directions';
import Similar from 'components/Page/Recipe/Similar';
import styled from 'styled-components';
import { Container, Card } from 'components/Theme/Grid';

const InstructionsWrapper = styled(Container)`
  
  
`;

const IngredientsCard = styled(Card)`
  
  flex-basis:30%;
  
`;

const DirectionsCard = styled(Card)`
  flex-basis:70%;
`;

class Recipe extends Component {

    render() {
      const { contentfulRecipe, allContentfulRecipe } = this.props.data;
      const { heroDescription, heroTitle, heroAsset } = contentfulRecipe
      const heroData = _.pickBy(contentfulRecipe, (v, k) => { return /^hero/.test(k) })
      const ingredientsData = _.pickBy(contentfulRecipe, (v, k) => { return /^ingredients/.test(k) })
      const directionsData = _.pickBy(contentfulRecipe, (v, k) => { return /^directions/.test(k) })
      const similarData = {'posts': allContentfulRecipe.edges, 'post': contentfulRecipe}

      return ( 
        <Layout >
          <SEO title={heroTitle} 
                description={heroDescription} 
                pageFixedImage={heroAsset && heroAsset.fixed} />

          <Hero data = { contentfulRecipe }/>

          <InstructionsWrapper neumorphic>
            <IngredientsCard noGutter sm={1} >
              <Ingredients data = { ingredientsData } servingSize={contentfulRecipe.directionsServings} />
            </IngredientsCard>

            <DirectionsCard noGutter sm={1}>
              <Directions data = { directionsData } ></Directions>
            </DirectionsCard>
          </InstructionsWrapper>
          
          <Similar data = { similarData }></Similar>
        </Layout >
      )
    }
}

export default Recipe

// language=GraphQL
export const recipeQuery = graphql`
  query RecipeQuery($id: String!) {
    contentfulRecipe(id: { eq: $id }) {
      heroTitle
      heroDescription
      heroAsset {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      author
      authorLink
      authorColor
      authorAvatar {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      handle
      ingredients {
        childMarkdownRemark {
          html
        }
      }

      ingredientsList {
        qty
        label
        measurement
      }

      directions {
        directions
        childMarkdownRemark {
          html
        }
      }
      directionsOvenTemp
      directionsTotalTime
      directionsServings
      directionsChefsNote {
        childMarkdownRemark {
          html
        }
      }
      tags
    }
    allContentfulRecipe(limit: 1) {
      edges {
        node {
          tags
          handle
          heroTitle
          heroDescription
          heroAsset {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;