import React, { useState } from "react";

// Styles
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card } from 'components/Theme/Grid';
import { h3, body } from 'components/Theme/Copy';

// Components
import Button from 'components/Theme/Button'
import ReactHtmlParser from 'react-html-parser'
import { HiOutlineClipboardCopy } from 'react-icons/hi';

const DirectionsCard = styled(Card)`
  flex-direction: column;
  padding: 6rem;
  position:relative;
  
  margin-bottom:0;
  ${media.lessThan("tablet")`
    padding:0;
    margin-bottom:0;
  `};
`;

const Direction = styled(body)`
  font-size:1.6rem;
  line-height:3rem;
  width:80%;

  p {
    margin-bottom:0.5rem;
  }

  ol {
    margin:0 0 3rem 0;
    padding-inline-start:1.5rem;
    li {
      margin-bottom:1.5rem;
      padding-left:1rem;
    }
  }

  ul {
    list-style-type: disc;
    margin:0 0 1.5rem 0;
    padding-inline-start:1.5rem;
  }

  ${media.lessThan("tablet")`
    width:100%;
    padding:3rem;
  `};
`;

const Heading = styled(h3)`
  margin:0 0 3rem 0;
  font-size:1.4rem;
  letter-spacing:0.1em;
  ${media.lessThan("tablet")`
    font-size:1.6rem;
    margin-left:0;
    margin-bottom:2rem;
    padding-left:3rem;
    padding-bottom:0;
  `};
`;

const InfoContainer = styled.div`
  text-align:left;
  display:flex;
  margin:1.5rem 0 3rem 0;

  ${media.lessThan("tablet")`
    flex-direction:column;
    margin:3rem 1.5rem;
  `}
`;

const InfoItem = styled(body)`
  text-transform:uppercase;
  letter-spacing:0.1em;
  font-size:1.2rem;
  padding:0 1.5rem;
  margin:1.5rem 1.5rem 1.5rem 0;
  background-color:${p => p.theme.cardBgColorLight };
  color:${p => p.theme.cardTextColor };

  span {
    cursor:pointer;
  }

  ${media.lessThan("tablet")`
    margin:0.2rem;
  `}
`;

const ChefsNotes = styled(body)`
  border:0.1rem solid ${p => p.theme.brandPrimary};
  padding:2rem;
  border-radius:0.5rem;
  margin-bottom:3rem;
  margin-top:2rem;
  width:60%;
  ${media.lessThan("tablet")`
    width:90%;
    margin:0 auto;
  `}
`;

const NotesCopy = styled(body)`
  font-size:1.4rem;

  ${media.lessThan("tablet")`
    line-height:2.2rem;
  `}
`;

const NotesHeading = styled(body)`
  font-size:1.4rem;
  font-weight:bold;
`;

const FindMoreButton = styled(Button)`
  margin-top:2rem;

  ${media.lessThan("tablet")`
    margin:6rem auto;
    display:block;
  `}
`;

const CopyToClipboard = styled.div`
  cursor:pointer;
`;

const CopyToClipIcon = styled(HiOutlineClipboardCopy)`
  width:1.5rem;
  height:1.5rem;
  position:relative;
  top:0.2rem;
`;

const Directions = ({data}) => {
  const { directions, directionsTotalTime, directionsChefsNote, directionsOvenTemp } = data;
  const [copied, makeCopied] = useState(false);

  const copyToClipboard = () => {
    if (typeof window !== `undefined`) {
      navigator.clipboard.writeText(directions.directions)
    }

    makeCopied(true);
  }

  

  return (
    <Container>
      <DirectionsCard>
        
        <Heading>DIRECTIONS</Heading>
        <Direction>{ ReactHtmlParser(directions.childMarkdownRemark.html) }</Direction>

        {directionsChefsNote && 
          <ChefsNotes>
            <NotesHeading>Chef's note</NotesHeading>
            <NotesCopy>{ ReactHtmlParser( directionsChefsNote && 
                                          directionsChefsNote.childMarkdownRemark &&
                                          directionsChefsNote.childMarkdownRemark.html) }</NotesCopy>
          </ChefsNotes>
        
        
        }
        <InfoContainer>
          { directionsOvenTemp && <InfoItem>OVEN TEMP: <b>{directionsOvenTemp}</b></InfoItem> }
          { directionsTotalTime && <InfoItem>TOTAL TIME: <b>{directionsTotalTime}</b> </InfoItem> }
          {/* { directionsServings && <InfoItem>SERVINGS: <b>{directionsServings}</b></InfoItem> } */}

          <InfoItem>
            <CopyToClipboard onClick={copyToClipboard}>
              { copied ? 'COPIED' : 'COPY TO CLIPBOARD' } <b><CopyToClipIcon /></b>
            </CopyToClipboard>
          </InfoItem>
        </InfoContainer>
        <FindMoreButton to="/recipes">FIND MORE RECIPES</FindMoreButton>
      </DirectionsCard>
    </Container>
  )
}

export default Directions

